
import { defineComponent } from "vue";
import { useAuthStore } from "@/store/modules/auth.module";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Sidebar",
  data() {
    return {
      recommeLogo: require("@/assets/images/static/full_logo.png"),
      menu: [
        {
          type: "heading",
          text: "Menu",
        },
        {
          type: "item",
          text: "Dashboard",
          icon: "pi pi-home",
          to: "/",
        },
        {
          type: "item",
          text: "Wiadomości",
          icon: "pi pi-comments",
          to: "#",
          badge: "Wkrótce",
        },
        {
          type: "item",
          text: "Powiadomienia",
          icon: "pi pi-bell",
          to: "#",
          badge: "Wkrótce",
        },
        {
          type: "heading",
          text: "System",
        },
        {
          type: "item",
          text: "Klienci",
          icon: "pi pi-users",
          to: "/customers",
        },
        {
          type: "item",
          text: "Subskrypcje",
          icon: "pi pi-money-bill",
          to: "/subscriptions",
        },
        {
          type: "item",
          text: "Feature flags",
          icon: "pi pi-flag",
          to: "/features",
        },
        {
          type: "heading",
          text: "Ustawienia",
        },
        {
          type: "item",
          text: "Konto",
          icon: "pi pi-user-edit",
          to: "#",
          badge: "Wkrótce",
        },
        {
          type: "item",
          text: "Ustawienia",
          icon: "pi pi-cog",
          to: "#",
          badge: "Wkrótce",
        },
      ],
    };
  },
  setup() {
    const auth = useAuthStore();
    const router = useRouter();
    const logout = () => {
      auth
        .logout()
        .then(() => {
          router.push({ name: "Login" });
        })
        .catch((error) => console.error(error));
    };

    return {
      logout,
    };
  },
});
