<template>
  <i
    class="pi pi-spinner animate-spin opacity-80 loader"
    style="width: fit-content"
  >
  </i>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
