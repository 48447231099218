import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import CountryFlag from "vue-country-flag-next";

import { app } from "@/main";

import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import AuthLayout from "@/layouts/Auth.vue";
import SplitButton from "primevue/splitbutton";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export const registerPrimeVueComponents = (): void => {
  app.component("InputText", InputText);
  app.component("Button", Button);
  app.component("InputSwitch", InputSwitch);
  app.component("Dropdown", Dropdown);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Dialog", Dialog);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("MultiSelect", MultiSelect);
  app.component("Chip", Chip);
  app.component("SplitButton", SplitButton);
  app.component("Column", Column);
  app.component("DataTable", DataTable);
};

export const registerGlobalComponents = (): void => {
  app.component("spinner-loader", SpinnerLoader);
  app.component("admin-layout", AdminLayout);
  app.component("auth-layout", AuthLayout);
  app.component("country-flag", CountryFlag);
};
