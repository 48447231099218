
import { defineComponent } from "vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default defineComponent({
  components: {
    UserDropdown,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
});
