import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth-layout" },
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/",
    name: "Dashboard",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/customers",
    name: "CustomerList",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/Customer/List.vue"),
  },
  {
    path: "/customer/:id",
    name: "Customer",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/Customer/Edit.vue"),
  },
  {
    path: "/customer/:id/features",
    name: "CustomerFeatures",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/Customer/_CustomerFeatures.vue"),
  },
  {
    path: "/features",
    name: "FeaturesList",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/FeatureFlags/List.vue"),
  },
  {
    path: "/subscriptions",
    name: "SubscriptionPlanList",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/SubscriptionPlan/List.vue"),
  },
  {
    path: "/subscriptions/:subscriptionPlanId",
    name: "SubscriptionPlanEdit",
    meta: { layout: "admin-layout" },
    component: () => import("@/views/SubscriptionPlan/Edit.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
