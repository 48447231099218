import { defineStore } from "pinia";
import { User } from "@/types/User";
import ApiService from "@/api/api.service";
import { clearAllCookies } from "@/common/helpers";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    user: {} as User,
    isAuthenticated: false,
    errors: null,
    loading: false,
  }),
  getters: {},
  actions: {
    async login(credentials: Credentials) {
      this.$reset();
      await ApiService.get("auth/sanctum/csrf-cookie").catch(() => {
        console.error("Nie uzyskano csrf cookie");
      });

      await ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          this.user = data;
          this.isAuthenticated = true;
        })
        .catch((error) => {
          this.errors = error;
        });
      this.loading = false;
    },

    async logout() {
      this.loading = true;
      await ApiService.post("auth/logout")
        .then(() => {
          this.$reset();
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
          this.loading = false;
        });
    },

    async checkAuth() {
      await ApiService.get("/me")
        .then(({ data }) => {
          this.isAuthenticated = true;
          this.user = data;
        })
        .catch(() => {
          clearAllCookies();
          this.$reset();
        });
    },
  },
});
