import axios from "axios";

const ApiService = {
  init() {
    // TODO: Przenieść adresy na bazowe api serwisy
    axios.defaults.baseURL =
      "http://" + process.env.VUE_APP_BACKEND_REST_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  },
  get(endpoint: string) {
    return axios.get(endpoint).catch((error: any) => {
      throw new Error(`[RWV] ApiService Get: ${error}`);
    });
  },

  post(endpoint: string, payload?: any) {
    return axios.post(endpoint, payload).catch((error) => {
      throw new Error(`[RWV] ApiService Post: ${error}`);
    });
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
