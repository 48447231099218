import { createApp, h } from "vue";
import App from "@/App.vue";
import router from "@/router";
import axios from "axios";
import VueAxios from "vue-axios";
import ApiService from "@/api/api.service";
import "./assets/tailwind.css";
import { createPinia } from "pinia";
import { useAuthStore } from "@/store/modules/auth.module";
import { client } from "@/api/restapi.service";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/tailwind-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import FlashMessage from "@smartweb/vue-flash-message";
import ConfirmationService from "primevue/confirmationservice";
import {
  registerGlobalComponents,
  registerPrimeVueComponents,
} from "./common/component.service";

ApiService.init();

const pinia = createPinia();
const auth = useAuthStore(pinia);

router.beforeEach((to, from, next) => {
  auth.checkAuth().then(() => {
    to.params.key ? (client.defaults.headers["Customer"] = to.params.key) : "";
    if (to.name !== "Login" && !auth.isAuthenticated) next({ name: "Login" });
    else if (to.name === "Login" && auth.isAuthenticated)
      next({ name: "Dashboard" });
    else next();
  });
});

export const app = createApp({
  render: () => h(App),
})
  .use(router)
  .use(pinia)
  .use(FlashMessage, {
    name: "flashMessage",
    tag: "FlashMessage",
    time: 8000,
    strategy: "single",
  })
  .use(VueAxios, axios)
  .use(PrimeVue)
  .use(ConfirmationService);

registerGlobalComponents();
registerPrimeVueComponents();

app.mount("#app");
