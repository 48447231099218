<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      id="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      id="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div class="py-3 px-4 text-sm text-gray-900 dark:text-white">
        <div class="flex flex-row">
          <img
            alt="..."
            class="rounded-full align-middle border-none shadow-lg h-12 mr-3"
            :src="image"
          />
          <div class="flex flex-col align-items-center">
            <div>{{ auth.user.name }}</div>
            <div class="font-medium truncate">{{ auth.user.email }}</div>
          </div>
        </div>
      </div>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <a
        href="#"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="logout"
      >
        Wyloguj
      </a>
    </div>
  </div>
</template>

<script lang="js">
import {createPopper} from "@popperjs/core";
import {defineComponent} from "vue";
import {useAuthStore} from "@/store/modules/auth.module";
import {useRouter} from "vue-router";

export default defineComponent({
  // <!--CreatePooper nie działa poprawnie z composition api - stąd js-->
  data() {
    return {
      image: require("@/assets/images/static/img_avatar.png"),
      dropdownPopoverShow: false
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
  setup() {
    const auth = useAuthStore();
    const router = useRouter()
    const logout = () => {
      auth.logout().then(() => {
            router.push({name: "Login"});
          })
          .catch((error) => console.error(error));
    };

    return {
      logout,
      auth
    };
  },
});
</script>
