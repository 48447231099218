import axios, { AxiosResponse } from "axios";

export const client = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Accept": "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  baseURL: "https://" + process.env.VUE_APP_BACKEND_REST_API_URL,
});

const RestApiService = {
  get(endpoint: string, params: any = null) {
    return client.get(endpoint, { params: params }).catch((error: any) => {
      throw new Error(`[RWV] ApiService Get: ${error}`);
    });
  },

  post(endpoint: string, payload?: any) {
    return client.post(endpoint, payload).catch((error) => {
      throw new Error(`[RWV] ApiService Post: ${error}`);
    });
  },

  update(resource, slug, params) {
    return client.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return client.put(`${resource}`, params);
  },

  delete(resource) {
    return client.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default RestApiService;
